import React from 'react';
import Canvas from './Canvas';

function App() {
    return (
        <Canvas />
    );
}

export default App;
