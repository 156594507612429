import { drawMountain } from './drawMountain';

export const drawMountains = (ctx, canvas) => {
    // Function to calculate peak count based on canvas width
    const calculatePeakCount = (basePeakCount) => {
        const baseWidth = 1000; // Reference width for the base peak count
        const widthRatio = canvas.width / baseWidth; // Ratio of canvas width to the reference width
        return Math.max(3, Math.round(basePeakCount * widthRatio)); // Ensure at least 3 peaks
    };

    drawMountain(ctx, canvas, {
        baseHeight: 0.6,
        peakHeight: 0.12 * canvas.height,
        valleyDepth: 0.08 * canvas.height,
        peakCount: calculatePeakCount(7),
        color: '#afa27f',
        offsetX: 0,
    });

    drawMountain(ctx, canvas, {
        baseHeight: 0.50,
        peakHeight: 0.12 * canvas.height,
        valleyDepth: 0.08 * canvas.height,
        peakCount: calculatePeakCount(7),
        color: '#8c917d',
        offsetX: 0,
    });

    drawMountain(ctx, canvas, {
        baseHeight: 0.40,
        peakHeight: 0.15 * canvas.height,
        valleyDepth: 0.1 * canvas.height,
        peakCount: calculatePeakCount(5),
        color: '#5e5b54',
        offsetX: 0,
    });

    drawMountain(ctx, canvas, {
        baseHeight: 0.30,
        peakHeight: 0.18 * canvas.height,
        valleyDepth: 0.12 * canvas.height,
        peakCount: calculatePeakCount(4),
        color: '#292d30',
        offsetX: 0,
    });

    drawMountain(ctx, canvas, {
        baseHeight: 0.20,
        peakHeight: 0.03 * canvas.height,
        valleyDepth: 0.03 * canvas.height,
        peakCount: calculatePeakCount(20),
        color: '#191a14',
        offsetX: 0,
    });
};
