// drawMountain.js
export const drawMountain = (ctx, canvas, options = {}) => {
    // Destructure options with defaults
    const {
        baseHeight = 0.2, // The base height of the mountain as a fraction of the canvas height
        peakHeight = 0.15 * canvas.height, // Maximum deviation for peaks
        valleyDepth = 0.1 * canvas.height, // Maximum deviation for valleys
        peakCount = 5, // Number of peaks
        color = '#e0e0e0', // Default mountain color
        offsetX = 0, // Horizontal offset for the mountain
    } = options;

    // Set the mountain color
    ctx.fillStyle = color;

    // Begin the path for the mountain
    ctx.beginPath();

    // Start the line from the bottom-left corner with an offset
    ctx.moveTo(offsetX, 0);

    // Draw the first line up to the base height of the mountain
    const startX = offsetX;
    const startY = canvas.height * baseHeight;
    ctx.lineTo(startX, startY);

    // Calculate dynamic control points for the bezier curves
    const segmentWidth = (canvas.width - offsetX) / peakCount;

    // Variables to hold the previous endpoint
    let prevX = startX;
    let prevY = startY;

    for (let i = 1; i <= peakCount; i++) {
        const cp1X = prevX + segmentWidth * 0.25; // Control point 1 X (relative to previous endpoint)
        const cp1Y = prevY + (Math.random() * peakHeight - peakHeight / 2); // Control point 1 Y

        const cp2X = prevX + segmentWidth * 0.75; // Control point 2 X (relative to the first control point)
        const cp2Y = startY + (Math.random() * valleyDepth - valleyDepth / 2); // Control point 2 Y

        const endX = prevX + segmentWidth; // End point X (one segment width ahead)
        const endY = startY + (Math.random() * peakHeight - peakHeight / 2); // End point Y

        // Draw the bezier curve
        ctx.bezierCurveTo(cp1X, cp1Y, cp2X, cp2Y, endX, endY);

        // Update the previous endpoint for the next curve
        prevX = endX;
        prevY = endY;
    }

    // Draw a straight line to the bottom-right corner
    ctx.lineTo(canvas.width, 0);

    // Close the path by drawing a line back to the start
    ctx.lineTo(offsetX, 0);
    ctx.closePath();

    // Fill the mountain shape
    ctx.fill();
};
