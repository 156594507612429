// drawSun.js
export const drawSun = (ctx, canvas) => {
    ctx.fillStyle = '#cf8150'; // Yellow color for the sun
    ctx.beginPath();
    const sunX = canvas.width - 150;
    const sunY = canvas.height - 150;
    const sunRadius = 80;
    ctx.arc(sunX, sunY, sunRadius, 0, Math.PI * 2, false); // Circle for the sun
    ctx.fill();
  };
  