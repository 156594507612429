// drawTree.js
export const drawTree = (ctx, baseX, baseY, options = {}) => {
    // Destructure options with defaults
    const {
        trunkHeight = 50, // Default trunk height
        trunkWidth = 10, // Default trunk width
        leafSize = 40, // Default leaf size
        heightMultiplier = 1, // Multiplier for trunk height for 3D effect
        trunkColor = '#8B4513', // Brown color for the trunk
        leafColor = '#514c2f', // Green color for the leaves
        shadowColor = 'rgba(0, 0, 0, 0.2)', // Shadow color with transparency
        shadowOffsetX = 5, // Horizontal shadow offset
        shadowOffsetY = -5, // Vertical shadow offset (upwards because of inverted y-axis)
    } = options;

    // Adjust trunk height based on the multiplier
    const adjustedTrunkHeight = trunkHeight * heightMultiplier;
    const adjustedLeafSize = leafSize * heightMultiplier;

    // Set shadow properties
    ctx.shadowColor = shadowColor;
    ctx.shadowBlur = 10;
    ctx.shadowOffsetX = shadowOffsetX;
    ctx.shadowOffsetY = shadowOffsetY;

    // Draw trunk (adjusting for inverted y-axis)
    ctx.fillStyle = trunkColor;
    ctx.fillRect(baseX - trunkWidth / 2, baseY, trunkWidth, adjustedTrunkHeight);

    // Draw leaves (adjusting for inverted y-axis)
    ctx.fillStyle = leafColor;
    ctx.beginPath();
    ctx.moveTo(baseX, baseY + adjustedTrunkHeight); // Start at the top of the trunk
    ctx.lineTo(baseX - adjustedLeafSize, baseY + adjustedTrunkHeight - adjustedLeafSize); // Bottom left of the triangle
    ctx.lineTo(baseX + adjustedLeafSize, baseY + adjustedTrunkHeight - adjustedLeafSize); // Bottom right of the triangle
    ctx.closePath();
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(baseX, baseY + adjustedTrunkHeight + adjustedLeafSize / 2); // Middle section of the leaves
    ctx.lineTo(baseX - adjustedLeafSize * 0.75, baseY + adjustedTrunkHeight - adjustedLeafSize / 2); // Bottom left of this section
    ctx.lineTo(baseX + adjustedLeafSize * 0.75, baseY + adjustedTrunkHeight - adjustedLeafSize / 2); // Bottom right of this section
    ctx.closePath();
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(baseX, baseY + adjustedTrunkHeight + adjustedLeafSize); // Top section of the leaves
    ctx.lineTo(baseX - adjustedLeafSize * 0.5, baseY + adjustedTrunkHeight); // Bottom left of this section
    ctx.lineTo(baseX + adjustedLeafSize * 0.5, baseY + adjustedTrunkHeight); // Bottom right of this section
    ctx.closePath();
    ctx.fill();

    // Reset shadow settings after drawing the tree
    ctx.shadowColor = 'transparent';
};
