// Canvas.js
import React, { useRef, useEffect } from 'react';
import { drawMountains } from './utils/drawMountains';
import { drawSun } from './utils/drawSun';
import { drawTrees } from './utils/drawTrees';

const Canvas = () => {
    const canvasRef = useRef(null);

    const drawCanvas = (ctx, canvas) => {
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas

        // Apply transformation: move origin to bottom-left and invert y-axis
        ctx.save();
        ctx.translate(0, canvas.height);
        ctx.scale(1, -1);

        ctx.fillStyle = '#daa77a'; // Light blue color for the sky
        ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the entire canvas with the background color    
        ctx.save();

        // Draw different elements
        drawMountains(ctx, canvas);
        drawSun(ctx, canvas);
        drawTrees(ctx, canvas);

        ctx.restore(); // Restore the context to the original state
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            drawCanvas(ctx, canvas); // Redraw the canvas content after resizing
        };

        // Set initial size and draw
        resizeCanvas();

        // Event listener for window resize
        window.addEventListener('resize', resizeCanvas);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, []);

    return <canvas ref={canvasRef}></canvas>;
};

export default Canvas;
