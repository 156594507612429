// drawTrees.js
import { drawTree } from './drawTree';

export const drawTrees = (ctx, canvas) => {
    const rows = 3; // Number of rows
    const treesPerRow = Math.floor(6 / rows); // Distribute trees evenly across rows
    const baseYPositions = [canvas.height * 0, canvas.height * 0.05, canvas.height * 0.08]; // Base Y positions for each row
    const trees = [[], [], []]; // Arrays to store tree positions and sizes for each row

    // Function to check if a tree overlaps with any existing trees in the row
    const isOverlapping = (newTree, row) => {
        for (let i = 0; i < trees[row].length; i++) {
            const existingTree = trees[row][i];
            const distance = Math.hypot(newTree.baseX - existingTree.baseX, newTree.baseY - existingTree.baseY);
            const minDistance = (newTree.leafSize + existingTree.leafSize) * 0.75; // Increase overlap detection area by 75%
            if (distance < minDistance) {
                return true; // Trees are overlapping
            }
        }
        return false; // No overlap
    };

    // Start looping from the last row to the first row
    for (let row = rows - 1; row >= 0; row--) {
        for (let i = 0; i < treesPerRow; i++) {
            let treePlaced = false;
            let attempts = 0;
            let baseX, baseY, trunkHeight, trunkWidth, leafSize, heightMultiplier;

            // Try to find a non-overlapping position within 3 attempts
            while (!treePlaced && attempts < 3) {
                baseX = Math.random() * canvas.width; // Random x position across the canvas
                baseY = baseYPositions[row] + Math.random() * (canvas.height * 0.1); // Variable y position within the row

                // Adjust tree sizes based on row number
                const sizeMultiplier = 1 - row * 0.2; // Smaller trees in higher rows
                trunkHeight = 120 * sizeMultiplier; // Trunk height adjusted by row
                trunkWidth = 16 * sizeMultiplier; // Trunk width adjusted by row
                leafSize = 100 * sizeMultiplier; // Leaf size adjusted by row
                heightMultiplier = (Math.random() * 0.5 + 0.75) * sizeMultiplier; // Height multiplier for 3D effect

                const newTree = { baseX, baseY, leafSize };

                if (!isOverlapping(newTree, row)) {
                    trees[row].push(newTree);
                    drawTree(ctx, baseX, baseY, {
                        trunkHeight,
                        trunkWidth,
                        leafSize,
                        heightMultiplier,
                    });
                    treePlaced = true;
                }

                attempts++;
            }

            // Skip the tree if it overlaps after 3 attempts
            if (!treePlaced) {
                console.log(`Skipped tree in row ${row + 1} due to overlap after 3 attempts`);
            }
        }
    }
};
